import { SearchPage } from 'Components'
import TemplateSearchPage from 'Stores/_default-store/Templates/SearchPage.rt'
import { FacetPanel } from 'Components'
import { SearchBox } from 'Components'
import TemplateSearchBox from 'Stores/_default-store/Templates/SearchBox.rt'
import { SearchResult } from 'Components'
import { SearchHeader } from 'Components'
import { VehicleWidget } from 'Components'
import TemplatefitmentSearchhomeVehicleWidget from 'Stores/_default-store/Templates/fitmentSearch/homeVehicleWidget.rt'
import TemplatefitmentSearchheroVehicleWidget from 'Stores/timbren/Templates/fitmentSearch/heroVehicleWidget.rt'
import { Garage } from 'Components'
import TemplatefitmentSearchgarage from 'Stores/timbren/Templates/fitmentSearch/garage.rt'
import { VerifyFitment } from 'Components'
import TemplatefitmentSearchverifyFitment from 'Stores/timbren/Templates/fitmentSearch/verifyFitment.rt'
import { FitmentTable } from 'Components'
import TemplatefitmentSearchfitmentTable from 'Stores/timbren/Templates/fitmentSearch/fitmentTable.rt'
import { FacetDialog } from 'Components'
import TemplateFacetDialog from 'Stores/timbren/Templates/FacetDialog.rt'

export const compMap = {
  SearchPage,
FacetPanel,
SearchBox,
SearchResult,
SearchHeader,
VehicleWidget,
Garage,
VerifyFitment,
FitmentTable,
FacetDialog
} 
export const tplMap = {
  'SearchPage': TemplateSearchPage,
'SearchBox': TemplateSearchBox,
'fitmentSearch/homeVehicleWidget': TemplatefitmentSearchhomeVehicleWidget,
'fitmentSearch/heroVehicleWidget': TemplatefitmentSearchheroVehicleWidget,
'fitmentSearch/garage': TemplatefitmentSearchgarage,
'fitmentSearch/verifyFitment': TemplatefitmentSearchverifyFitment,
'fitmentSearch/fitmentTable': TemplatefitmentSearchfitmentTable,
'FacetDialog': TemplateFacetDialog
};