export default {
  BUTTON_PRIMARY_CLASS: 'btn btn--primary',
  BUTTON_SECONDARY_CLASS: 'btn btn--secondary',

  LOAD_MORE_TEXT: 'load more products',

  SEARCH_PAGE_CLASS:
    "{this.responseState === 'nothing' && !this.responseQuery ? 'cm_SearchPage__nothing' : ''}",

  FACET_TITLE_ICON: `
    <div class="{!this.isCollapsed ? 'chevron-down' : ''}" template="icons/facet-chevron" />`,
  CURRENT_SEARCH: 'active filters',
  START_OVER: 'clear all',

  SORT_BY: 'sort <rt-virtual template="icons/sort" />',
  FILTERS_ICON: `<rt-virtual template="icons/filter" />`,

  VERIFY_FITMENT_ICON: `
    <rt-virtual rt-if="this.fits === 'yes'" template="icons/verify-fitment-yes" />
    <rt-virtual rt-if="this.fits === 'no'" template="icons/verify-fitment-no" />
    <rt-virtual rt-if="this.fits === 'universal' || this.fits === 'unknown' || this.fits === 'aggressive'" template="icons/verify-fitment-info" />`,
  GARAGE_TITLE:
    '{this.name === "HeroGarage" || this.name === "VerifyFitmentGarage" ? "view your garage" : "garage"}',
  GARAGE_ICON: '<rt-virtual template="icons/garage" />',
  ADD_VEHICLE: 'add more vehicles',

  SELECT_YOUR_VEHICLE_BLOCK: '<div class="text">select your vehicle</div><div class="line"></div>',
  SELECT_YOUR_VEHICLE: '<div class="text">select your vehicle</div><div class="line"></div>',
  GO_TEXT: 'search',
  CLEAR_TEXT: 'reset',
  DISCARD_VEHICLE: 'clear vehicle selection',

  VERIFY_FITMENT_FITS: 'This product will fit your selected vehicle',
  VERIFY_FITMENT_NOT_FIT: 'This product will not fit your selected vehicle',
  FITMENT_UNIVERSAL_TITLE: 'This product is for trailers, and will require you to manually verify fitment',
  FITMENT_UNKNOWN_TITLE: 'This product doesn’t have any vehicle fitment data',

  SEARCH_BOX_CLASS: 'search__predictive__form',
  SEARCH_BOX_PLACEHOLDER: 'What can we help you find?',
};
